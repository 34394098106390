import React from "react";
import cn from "classnames";
export default function Section({ children, className, variant = "normal" }) {
  return (
    <div
      className={cn(
        ` w-full h-auto flex flex-col`,
        {
          "md:py-8 px-4 md:px-8 lg:px-[5vw]": variant === "flat",
          "md:py-16 px-4 md:px-8 lg:px-[5vw]": variant === "normal",
          "py-0 px-4 md:px-8 lg:px-[5vw]": variant === "zero",
          "py-0 px-2 md:px-8 lg:px-[5vw]": variant === "carousel",
        },
        className
      )}
    >
      {children}
    </div>
  );
}
