import React from "react";
import cn from "classnames";
import Link from "next/link";
import Text from "../ui/text";
import TickOutline from "../icons/tick-outline";
import Lock from "../icons/lock";
import Repeat from "../icons/repeat";

export default function verified({ className }) {
  const iconClass = "text-white w-[32px] h-[32px] -translate-y-1";
  const data = [
    {
      icon: <Repeat className={iconClass} />,
      title: "Trust",
      descripton:
        "Explore the community of more than 40,000 individuals, showcasing our dedication to superior service and customer contentment.",
      link: "/what-people-have-to-say",
    },
    {
      icon: <Lock className={iconClass} />,
      title: "Secure payments & EMI",
      descripton:
        "We offer a secure payment gateway & zero interest EMI to ensure the safety of your transactions. ",
    },
    {
      icon: <TickOutline className={iconClass} />,
      title: "Legitimacy",
      descripton:
        "Shop on HypeFly with assurance, as each purchase undergoes a 7 step verification process.",
      link: "/authentication-process",
    },
  ];
  return (
    <div
      className={cn(
        "w-full grid md:grid-cols-3 grid-cols-1 auto-rows-auto gap-4",
        className
      )}
    >
      {data.map((row, key) => (
        <Tile data={row} key={"verify" + key} />
      ))}
    </div>
  );
}
const Tile = ({ data }) => {
  const { title, descripton, link, icon } = data;
  return (
    <div className="w-full md:px-4 md:py-6 p-4 bg-black flex flex-row gap-3 items-start justify-start rounded">
      {icon}
      <div className="flex flex-col gap-2 font-avery w-full justify-start items-start">
        <Text variant="subTitle" className="text-white mb-0 font-semibold">
          {title}
        </Text>
        <Text className="text-white leading-tight lg:max-w-[90%]">
          {descripton}
        </Text>
        {link && (
          <Link
            href={link}
            className="text-sm text-white border-b leading-none border-white hover:opacity-80 transition-all duration-300 ease"
          >
            Know More
          </Link>
        )}
      </div>
    </div>
  );
};
