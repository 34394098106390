import React from "react";
import ProductsBlock from "../products-block";
import { useQuery } from "@/framework/queries/graphql";
import { QUERY_ENDPOINTS } from "@/framework/utils/query-endpoints";
export default function Products({
  sectionHeading,
  collectionSlug,
  categorySlug,
  limit = 15,
}) {
  const [{ data, fetching: loading, error }] = useQuery({
    query: QUERY_ENDPOINTS.PRODUCTS_COLLECTION_BY_SLUG,
    variables: {
      start: 0,
      limit: 15,
      sortBy: ["order:asc"],
      slug: collectionSlug,
      filter: {},
    },
    pause: !collectionSlug,
  });

  return (
    <ProductsBlock
      sectionHeading={sectionHeading}
      products={data?.collections?.data[0]?.attributes?.productSelector?.map(
        (row) => row.product
      )}
      categorySlug={categorySlug}
      uniqueKey="new-arrivals"
      variant="gridModern"
      loading={!data}
      error={error}
      hideProductDescription={true}
      limit={limit}
    />
  );
}
