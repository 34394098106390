import React from "react";
import { Collapse } from "../components/common/accordion";
import Section from "@/components/ui/section";
import SectionHeader from "@/components/common/section-header";
export default function Faq({ data }) {
  return (
    <Section>
      <SectionHeader sectionHeading={"FAQ"} className=" mt-12 md:mt-0" />
      {data?.map((row, key) => (
        <Collapse
          variant="faq"
          key={"faq" + key}
          title={row?.attributes?.question}
          content={
            <p className="font-light text-base leading-tight px-4 py-4">
              {row?.attributes?.answer}
            </p>
          }
        />
      ))}
    </Section>
  );
}
