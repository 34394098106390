import Link from "next/link";
import Image from "next/image";
import Text from "../ui/text";
import LinkIcon from "../icons/link-icon";
import { m } from "@/lib/framer-motion";

const Card = ({ item, effectActive = false, href, showName = true }) => {
  const { name, image } = item ?? {};
  const placeholderImage = `/images/product-list.svg`;
  const imageSrc =
    image?.data?.attributes?.formats?.small?.url ??
    image?.data?.attributes?.url ??
    placeholderImage;

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 100 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
      <Link
        href={href}
        className="group flex justify-center text-center flex-col rounded-full"
      >
        <div
          className={`relative inline-flex mb-3.5 md:mb-4 lg:mb-5 xl:mb-6 mx-auto lg:w-44 lg:h-44 md:w-32 md:h-32 w-24 h-24 rounded-full`}
        >
          <div className="flex w-full h-full items-center relative rounded-full overflow-hidden">
            <Image
              src={imageSrc}
              alt={
                `Product Category - ${image?.data?.attributes?.alternativeText}` ??
                image?.data?.attributes?.alternativeText ??
                "text-card-thumbnail"
              }
              width={344}
              height={344}
              // fill
              quality={50}
              className={`object-cover bg-gray-300 rounded-full group-hover:scale-105 transition-all duration-300 ease`}
            />
          </div>
          {effectActive === true && (
            <>
              <div
                className={`absolute top left bg-black w-full h-full opacity-0 transition-opacity duration-300 group-hover:opacity-30 rounded-full`}
              />
              <div className="absolute top left h-full w-full flex items-center justify-center">
                <LinkIcon className="text-white w-6 h-6 transform opacity-0 scale-0 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:scale-100" />
              </div>
            </>
          )}
        </div>
        {!!showName !== false && (
          <Text
            variant="subTitle"
            className="capitalize font-medium lg:min-h-[48px] min-h-[40px]"
          >
            {name ?? "loading..."}
          </Text>
        )}
      </Link>
    </m.div>
  );
};

export default Card;
