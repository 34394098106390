import React, { useEffect } from "react";
import Link from "next/link";
import Alert from "@/components/ui/alert";
import SectionHeader from "@/components/common/section-header";
import Section from "@/components/ui/section";
import { sanitize } from "@/framework/utils/sanitize";
import { event } from "@/lib/ga";
import ProductCardGridLoaderSmall from "@/components/ui/loaders/product-card-grid-loader-small";
import ProductCardGrid from "@/components/product/product-card-grid";

const ProductsBlock = ({
  sectionHeading,
  categorySlug,
  className = "",
  products,
  error,
  loading,
  limit = 15,
}) => {
  useEffect(() => {
    if (products) {
      const productList = products?.map((product) => ({
        item_id: product?.data?.id,
        item_name: product?.data?.attributes?.name,
        item_category:
          product?.data?.attributes?.productType?.data?.attributes?.name,
      }));
      const payload = {
        item_list_id: categorySlug,
        item_list_name: sectionHeading,
        items: productList,
      };
      event("view_item_list", payload);
    }
  }, [products]);
  return (
    <Section>
      {sectionHeading && (
        <SectionHeader
          sectionHeading={sectionHeading}
          className="pb-0.5 mb-6 md:mt-0 mt-8"
        />
      )}

      {error ? (
        <Alert message={error?.message} />
      ) : (
        <div className="w-full flex flex-col gap-0 relative overflow-hidden ">
          <div className="w-full grid auto-rows-auto lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:gap-10 md:gap-6 gap-4">
            {loading
              ? Array.from({ length: limit }).map((_, idx) => {
                  return (
                    <div
                      key={`card-circle-${idx}`}
                      className="w-full lg:h-[320px] h-[300px]"
                    >
                      <ProductCardGridLoaderSmall
                        uniqueKey={`card-circle-${idx}`}
                      />
                    </div>
                  );
                })
              : products?.map(
                  (product, key) =>
                    key < limit && (
                      <ProductCardGrid
                        key={`${sectionHeading}--key${key}`}
                        product={sanitize(product?.data)}
                      />
                    )
                )}
          </div>

          {categorySlug && (
            <div className="w-full flex items-center justify-center mt-8">
              <Link
                href={categorySlug}
                className={`w-fit lg:mt-8 mx-auto h-11 md:h-12 md:px-8 px-8 bg-heading text-white py-2 transform-none normal-case hover:text-white hover:bg-gray-600 text-[13px] md:text-base leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none`}
              >
                <span className="py-2 3xl:px-8">View All</span>
              </Link>
            </div>
          )}
        </div>
      )}
    </Section>
  );
};

export default ProductsBlock;
