import React, { useState } from "react";
import cn from "classnames";
import { AnimatePresence, m } from "@/lib/framer-motion";
import { heightCollapse } from "@/utils/motion/height-collapse";
import ChevronDown from "../icons/chevron-down";
export const Collapse = ({
  i,
  icon,
  tag,
  titleKey,
  title,
  content,
  variant = "gray",
}) => {
  const [expanded, setExpanded] = useState(false);
  const isOpen = i === expanded;

  return (
    <div
      className={cn(
        {
          "rounded-md bg-gray-200": variant === "gray",
          "shadow-sm bg-gray-200": isOpen,
        },
        "w-full transition-all duration-200 ease hover:bg-gray-200"
      )}
    >
      <m.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className={cn(
          "cursor-pointer flex items-center justify-between transition-colors py-5 md:py-6",
          {
            "px-6 md:px-8 lg:px-10": variant === "gray",
            "border-t border-gray-300": variant === "transparent",
            "border-b border-gray-300 px-4": variant === "faq",
          }
        )}
      >
        <div className="flex flex-row items-center gap-4">
          {icon && icon}
          <h2
            className={cn(
              "text-sm font-semibold leading-relaxed text-heading pr-2",
              {
                "md:text-base": variant === "gray",
                "md:text-base lg:text-base": variant === "transparent",
                "lg:text-base text-sm": variant === "faq",
              }
            )}
          >
            {titleKey ? t(titleKey) : title}
          </h2>
        </div>
        <div className="flex flex-row items-center gap-2 mr-2">
          {tag && tag}
          <ChevronDown
            className={`w-[18px] h-[18px] text-black ${
              isOpen ? "-rotate-180" : "rotate-0"
            } transition-all duration-300 ease transform`}
          />
        </div>
      </m.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.div
            key="content"
            initial="from"
            animate="to"
            exit="from"
            variants={heightCollapse()}
          >
            <div
              className={cn("leading-7 text-sm text-gray-600 bg-white", {
                "pt-5 border-t border-gray-300 px-6 md:px-8 lg:px-10":
                  variant === "gray",
              })}
            >
              {content}
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
};
