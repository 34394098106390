export function getLowestPricedVariant(variants) {
  // Initialize variables to store the variant with the lowest sale price and the lowest sale price itself
  let lowestSalePriceVariant = null;
  let lowestSalePrice = Infinity;

  if (variants) {
    // Iterate through each variant
    variants.forEach((variant) => {
      // Extract salePrice or default to 0 if not present
      const salePrice = variant.salePrice || 0;
      const quantity = variant.quantity || 0;

      // Update lowestSalePriceVariant and lowestSalePrice if salePrice is non-zero and lower than the current lowestSalePrice
      if (salePrice !== 0 && salePrice < lowestSalePrice && quantity > 0) {
        lowestSalePriceVariant = variant;
        lowestSalePrice = salePrice;
      }
    });
  }

  // Return the variant with the lowest sale price
  return lowestSalePriceVariant;
}
