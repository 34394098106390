import ContentLoader from "react-content-loader";

const ProductCardGridLoaderSmall = (props) => (
  <ContentLoader
    speed={2}
    width={320}
    height={320}
    viewBox="0 0 320 320"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="w-full h-auto"
    {...props}
  >
    <rect x="20" y="240" rx="3" ry="3" width="180" height="8" />
    <rect x="20" y="260" rx="3" ry="3" width="280" height="6" />
    <rect x="20" y="280" rx="3" ry="3" width="80" height="10" />
    <rect x="20" y="0" rx="6" ry="6" width="280" height="200" />
  </ContentLoader>
);

export default ProductCardGridLoaderSmall;
