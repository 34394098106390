import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cn from "classnames";
import ChevronRight from "@/components/icons/chevron-right";
import ChevronLeft from "@/components/icons/chevron-left";
export default function Slick({
  children,
  slidesToScroll = 1,
  slidesToShow = 3,
  dots = false,
  infinite = false,
  navigation = true,
  fade = false,
  autoplay = true,
  onSlideChange,
  currentSlide,
  prevButtonClasses = "left-0",
  nextButtonClasses = "right-0",
  type = "circle",
  buttonSize = "default",
  ...props
}) {
  let nextButtonClassName = cn(
    "z-[1] top-1/3 absolute lg:w-12 lg:h-12 h-10 w-10 text-black flex items-center justify-center rounded bg-white shrink-0 transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none transform shadow-navigation",
    {
      "rounded-full": type === "circle",
      "text-sm md:text-base lg:text-xl 3xl:text-2xl": buttonSize === "default",
      "!static": type === "list",
    },
    nextButtonClasses
  );

  let prevButtonClassName = cn(
    "z-[1] top-1/3 absolute lg:w-12 lg:h-12 h-10 w-10 text-black flex items-center justify-center rounded bg-white shrink-0 transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none transform shadow-navigation",
    {
      "rounded-full": type === "circle",
      "text-sm md:text-base lg:text-xl 3xl:text-2xl": buttonSize === "default",
      "!static": type === "list",
    },
    prevButtonClasses
  );

  const NextButton = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className={nextButtonClassName}
        aria-label="next-button"
      >
        <ChevronRight className="w-5" />
      </button>
    );
  };
  const PrevButton = ({ onClick }) => {
    return (
      <button
        className={prevButtonClassName}
        aria-label="prev-button"
        onClick={onClick}
      >
        <ChevronLeft className="w-5" />
      </button>
    );
  };
  const [slideIndex, setSlideIndex] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  let sliderRef = useRef(null);

  const handleBeforeChange = (current, next) => {
    setActiveSlide(next); // Update active slide
    if (onSlideChange) onSlideChange(next);
  };
  useEffect(() => {
    setActiveSlide(currentSlide);
    sliderRef.slickGoTo(currentSlide);
  }, [currentSlide]);

  var settings = {
    dots: dots,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: navigation ? <NextButton /> : false,
    prevArrow: navigation ? <PrevButton /> : false,
    swipeToSlide: true,
    pauseOnHover: false,
    fade: fade,
    autoplay: autoplay,
    autoplaySpeed: 6000,
    draggable: true,
    accessibility: true,
    beforeChange: (current, next) => {
      setSlideIndex(next);
      if (onSlideChange) onSlideChange(next); // Call the callback here
    },
    beforeChange: handleBeforeChange,
    ...props,
    // cssEase: "linear",
  };

  return (
    <Slider
      ref={(slider) => {
        sliderRef = slider;
      }}
      {...settings}
    >
      {children}
    </Slider>
  );
}
