export function sanitize(row = {}) {
  let obj = row;
  // Check if the input is an object
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return as is if not an object
  }

  // Create a new object to store the flattened structure
  const result = {};

  // Iterate through the object properties
  for (const key in obj) {
    // Skip the "attributes" property
    if (key === "attributes") {
      // If the value of "attributes" is also an object, flatten it recursively
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(result, sanitize(obj[key]));
      }
    } else {
      // Copy the property to the result object
      result[key] = obj[key];
    }
  }

  return result;
}
