import Text from "../ui/text";

const SectionHeader = ({
  sectionHeading = "text-section-title",
  className = "pb-0.5 mb-6",
  textClassName = "text-center uppercase m-auto font-avenir px-8 bg-white z-[1]",
}) => {
  return (
    <div
      className={`w-full flex flex-col items-center justify-between md:py-5 py-2 sneaker-text-container relative ${className}`}
    >
      <Text className={textClassName} variant="subHeading">
        {sectionHeading}
      </Text>
    </div>
  );
};

export default SectionHeader;
