import React from "react";
import Link from "next/link";
import Image from "next/image";
import { event } from "@/lib/ga";
import cn from "classnames";
export default function BannerCard({
  banner,
  className,
  variant = "normal",
  href = "#",
  promo = false,
  index = 1,
  activeSlide = false,
}) {
  const placeholderImage = `/assets/placeholder/products/product-list.svg`;
  const { alternativeText = "", url, formats } = banner?.data?.attributes ?? "";
  // const imageUrl =
  //   width < 479 ? formats?.medium?.url ?? url : url ?? placeholderImage;
  const imageUrl = url ?? placeholderImage;

  return (
    <Link
      href={href}
      className={cn(
        "w-full group flex justify-center relative overflow-hidden !outline-none !ring-0 !border-0",
        {
          "md:h-[45vw] h-[400px]": variant === "normal",
          "md:h-[35vw] h-[200px]":
            variant === "small" || variant === "runningHome",
          "md:h-[20vw] h-[150px] mr-8": variant === "mini",
        }
      )}
    >
      {imageUrl && (
        <Image
          onClick={() => {
            if (promo && href && alternativeText) {
              const payload = {
                creative_name: "Landing Page Carousel Slide " + index,
                promotion_name: alternativeText,
                items: [{ item_name: href }],
              };
              event("select_promotion", payload);
            }
          }}
          src={imageUrl}
          alt={url}
          // quality={100}
          fill
          className={cn(
            {
              "object-center": variant === "normal",
              "object-center": variant === "mini",
              "object-center": variant === "small",
              "object-[50%_30%] object-cover": variant === "runningHome",
            },
            `object-cover`
          )}
          loading={activeSlide ? "eager" : "lazy"}
          priority={activeSlide ? true : false}
          sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 500px"
        />
      )}
    </Link>
  );
}
