import BannerCard from "@/components/common/banner-card";
import Slick from "@/components/ui/carousel/slick";
import { useState } from "react";
const HeroBlock = ({
  data,
  slidesPerView = 1,
  variant,
  loading = false,
  dots,
  ...rest
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <div className="heroBannerOne relative mb-0 mx-auto overflow-hidden bg-white w-full">
      <Slick
        dots={dots}
        navigation={false}
        infinite={false}
        slidesToScroll={1}
        slidesToShow={slidesPerView}
        fade={false}
        loop={false}
        currentSlide={currentSlide}
        onSlideChange={setCurrentSlide}
        autoplay={false}
        {...rest}
        dotsClass={"slick-dots slick-thumb banner"}
        appendDots={(dots) => <ul>{dots}</ul>}
        customPaging={(i) => (
          <div
            className={`ft-slick__dots--custom_banner ${
              currentSlide === i ? "active" : ""
            }`}
          ></div>
        )}
      >
        {!data || loading ? (
          <BannerCard
            banner={{
              data: {
                attributes: {
                  url: "/assets/placeholder/products/product-list.svg",
                },
              },
            }}
            href={"#"}
            effectActive={false}
            promo={false}
            index={1}
            activeSlide={true}
            variant={variant}
          />
        ) : (
          data?.map((row, key) => {
            return (
              <div key={`banner--key-${row?.id}`}>
                <div className="w-full h-auto md:flex hidden items-center justify-center outline-none ring-0">
                  <BannerCard
                    banner={row.image}
                    href={row.link}
                    effectActive={true}
                    promo={true}
                    index={key + 1}
                    activeSlide={currentSlide == key}
                    variant={variant}
                  />
                </div>
                <div className="w-full h-auto md:hidden flex items-center justify-center outline-none ring-0">
                  <BannerCard
                    banner={row.mobileImage}
                    href={row.link}
                    effectActive={true}
                    promo={true}
                    index={key + 1}
                    activeSlide={currentSlide == key}
                    variant={variant}
                  />
                </div>
              </div>
            );
          })
        )}
      </Slick>
    </div>
  );
};

export default HeroBlock;
