import React from "react";
import cn from "classnames";

const Text = ({ style, className, variant = "body", children, html }) => {
  const componentsMap = {
    body: "p",
    mediumHeading: "h3",
    heading: "h4",
    pageHeading: "h1",
    subHeading: "h2",
    subTitle: "p",
  };

  const Component = componentsMap[variant];

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {};

  return (
    <Component
      className={cn(
        {
          "text-sm lg:text-base text-black": variant === "subTitle",
          "text-sm": variant === "body",
          "text-body leading-tight": variant === "body",
          "text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold":
            variant === "mediumHeading",
          "text-heading":
            variant === "mediumHeading" ||
            variant === "heading" ||
            variant === "pageHeading" ||
            variant === "subHeading",
          "text-sm md:text-base xl:text-xl font-semibold":
            variant === "heading",
          "text-2xl font-bold": variant === "pageHeading",
          "text-lg md:text-2xl xl:text-3xl 2xl:text-4xl font-bold":
            variant === "subHeading",
        },
        className
      )}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  );
};

export default Text;
