const Alert = ({ message, className }) => {
  return (
    <div
      className={`${className} h-full py-4 px-5 text-sm text-red font-semibold flex items-center justify-center border border-red-200 rounded`}
    >
      {message}
    </div>
  );
};

export default Alert;
